/* styles.css */
.route-container {
    margin-top: 20px; /* Adjust the value as needed */
  }
/* General Body Styling */
body {
    font-family: 'Helvetica Neue', Arial, sans-serif; /* Match J.Crew's font style */
    background-color: #f4f4f4; /* Light grey background for the page */
    color: #333; /* Dark grey text for readability */
  }
  
  .nav-bar {
    background-color: #13518f; /* Trendy dark blue */
    color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  .grid-container {
    padding: 20px;
  }
  .card {
    background-color: #fff; /* White background for cards */
    border: 1px solid #ddd; /* Subtle border for the cards */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Soft shadow for depth */
  }
  .card-content {
    padding: 15px;
  }
  
  .nav-link {
    margin-right: 20px; /* Adjust the right margin to increase spacing */
    color: inherit; /* Inherits color from parent elements for consistency */
    text-decoration: none; /* Removes underline from links */
  }

  /* Filters Styling */
.filter-container {
    margin: 20px 0;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .filter-button {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px 0;
    cursor: pointer;
  }
  
  .filter-button:hover {
    background-color: #004494;
  }
  
  .nav-link:last-child {
    margin-right: 0; /* Removes margin from the last element to maintain padding */
  }
  
  .active {
    font-weight: bold; /* Highlights the active navigation link */
  } 

  /* Typography */
h1, h2, h3, h4 {
    color: #222; /* Slightly darker color for headings */
  }
  
  /* Button Styling */
  .button {
    background-color: #0055a6; /* J.Crew's blue for primary buttons */
    color: white;
    border: none;
    padding: 8px 16px;
    text-transform: uppercase;
    border-radius: 4px; /* Rounded corners for buttons */
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #003973; /* Darker blue on hover */
  }

  /* Responsive Design */
@media (max-width: 768px) {
    .grid-container {
      flex-direction: column;
    }
  }