.bikes-list {
    font-family: Arial, sans-serif;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  
  .tile {
    border: 2px solid green;
    border-radius: 10px;
    width: 200px;
    text-align: center;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .tile:hover {
    transform: scale(1.05);
  }
  
  .tile a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .tile img {
    width: 100%;
    height: auto;
  }
  
  .white-bg {
    background-color: white;
  }
  
  .black-bg {
    background-color: black;
    color: white;
  }
  
  .black-bg a {
    color: white;
  }
  